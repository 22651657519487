import { ReactComponent as ShareIcon } from "@/assets/icons/share.svg";

import { useAppSelector } from "@/store/app/hooks";

import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

export const Heading = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const { legal_name } = carrierReportData || {};

  return (
    <div className="flex justify-between items-center gap-2">
      <h2 className="text-xl font-semibold">{legal_name}</h2>
      <a
        href={`https://www.google.com/search?q=${legal_name}`}
        className="text-brand-700 font-semibold flex gap-2 text-sm shrink-0"
        target="_blank"
        rel="noreferrer"
      >
        Open in Google <ShareIcon />
      </a>
      {/* <div className="flex justify-between items-center text-sm">
        <div className="flex gap-2">
          <p className="text-gray-500">Google reviews</p>
          <span className="font-medium">{RATING}</span>
          <Rating value={RATING} />
          <span className="font-medium text-brand-blue-700">112 reviews</span>
        </div>
        <a
          href={`https://www.google.com/search?q=${legal_name}`}
          className="text-brand-700 font-semibold flex gap-2"
          target="_blank"
          rel="noreferrer"
        >
          Open in Google <ShareIcon />
        </a>
      </div> */}
    </div>
  );
};
