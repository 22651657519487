import { FC, useEffect, useRef, useState } from "react";
import {
  getFuelPriceApi,
  getTollApi,
  updatePricePrecision,
} from "@/modules/server";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  includeDeadHeadSelector,
  loadDataSelector,
} from "@/store/features/load-info/loadInfoSelectors";
import {
  resetTollCost,
  setDistance,
  setDistanceDifference,
  setDuration,
  setFuelPrice,
  setLoading,
  setTollCost,
} from "@/store/features/trip-info/tripInfoSlice";
import {
  getDistance,
  getDuration,
  getPolyline,
  getRequestData,
} from "@/utils/mapUtils";
import { IRouteRequest } from "@/types/common";
import { distanceDifferenceSelector } from "@/store/features/trip-info/tripInfoSelectors";
import TollIcon from "@/assets/icons/toll.png";

const center = {
  lat: 37.839333,
  lng: -84.27002,
};

export const Map: FC = () => {
  const dispatch = useAppDispatch();
  const [result, setResult] = useState<any>(null);

  const loadData = useAppSelector(loadDataSelector);
  const includeDeadHead = useAppSelector(includeDeadHeadSelector);
  const distanceDifference = useAppSelector(distanceDifferenceSelector);

  const mapRef = useRef<any>(null);
  const directionsService = useRef<any>(null);
  const directionsRenderer = useRef<any>(null);

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    if (
      loadData?.origin &&
      loadData.origin !== "" &&
      loadData.destination !== ""
    ) {
      const request = getRequestData(loadData);
      requestRouteData(request);
    }
    // eslint-disable-next-line
  }, [loadData?.origin, loadData?.destination]);

  useEffect(() => {
    if (result) {
      setMap(result, includeDeadHead);
    }
    // eslint-disable-next-line
  }, [includeDeadHead, result]);

  const initializeMap = () => {
    const mapState = {
      center,
      zoom: 5,
      options: {
        gestureHandling: "greedy",
        disableDefaultUI: true,
      },
    };
    const mapContainer = document.getElementById("map");
    if (!mapContainer) return;
    const map = new google.maps.Map(mapContainer, mapState);

    mapRef.current = map;
    directionsService.current = new google.maps.DirectionsService();
    directionsRenderer.current = new google.maps.DirectionsRenderer();
  };

  const requestRouteData = (request: IRouteRequest) => {
    directionsService.current.route(request, (result: any, status: any) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setResult(result);
        setMap(result, includeDeadHead);
      }
    });
  };

  const setMap = (result: any, includeDeadHead: boolean) => {
    const legs =
      includeDeadHead || result?.routes[0]?.legs.length === 1
        ? result?.routes[0]?.legs
        : result?.routes[0]?.legs.slice(1);

    const distance = getDistance(legs);
    const duration = getDuration(legs) as string;

    dispatch(setDistance(distance));
    dispatch(setDuration(duration));

    if (includeDeadHead && !distanceDifference.miles) {
      const diff = result?.routes[0]?.legs[0].distance.value;

      dispatch(
        setDistanceDifference({
          kilometers: Math.round(diff / 1000),
          miles: Math.round(diff * 0.000621371),
        })
      );
    }

    const polyline = getPolyline(legs);

    getToll(polyline).then((tollCost) => {
      dispatch(
        setTollCost({
          usd: tollCost.toString(),
          cad: (tollCost * 1.36).toFixed(2),
        })
      );
      getFuelPrice(legs[0]?.start_location);
    });

    const renderObject = {
      ...result,
      geocoded_waypoints: includeDeadHead
        ? result.geocoded_waypoints
        : result.geocoded_waypoints.slice(1),
      routes: [
        {
          ...result.routes[0],
          legs,
          overview_polyline: {
            points: polyline,
          },
        },
      ],
    };

    directionsRenderer.current.setMap(mapRef.current);
    directionsRenderer.current.setDirections(renderObject);
  };

  const getFuelPrice = async (startLocation: any) => {
    dispatch(setLoading(true));

    const lat = startLocation.lat();
    const lon = startLocation.lng();

    getFuelPriceApi(lat, lon)
      .then((fuelPrice) => {
        updatePricePrecision(fuelPrice);
        dispatch(
          setFuelPrice({
            gallon: fuelPrice.diesel.toString(),
            liter: ((fuelPrice.diesel / 3.785) * 1.36).toFixed(2),
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getToll = async (polyline: string): Promise<number> => {
    try {
      dispatch(setLoading(true));
      const data = await getTollApi(polyline);

      if (!data.costs.minimumTollCost) {
        dispatch(resetTollCost());
        return 0;
      } else {
        dispatch(
          setTollCost({
            usd: data.costs.minimumTollCost.toString(),
            cad: (data.costs.minimumTollCost * 1.36).toFixed(2),
          })
        );
      }

      data.tolls.forEach((toll: any) => {
        new google.maps.Marker({
          position: { lat: toll.lat, lng: toll.lng },
          map: mapRef.current,
          icon: {
            url: TollIcon,
            scaledSize: new google.maps.Size(30, 30),
          },
        });
      });

      dispatch(setLoading(false));
      return data.costs.minimumTollCost;
    } catch (e) {
      dispatch(resetTollCost());
      dispatch(setLoading(false));

      return 0;
    }
  };

  return <div id="map" className="w-full h-full relative"></div>;
};
