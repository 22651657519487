import { useEffect } from "react";
import { ChoroplethMap } from "./ChoroplethMap";

import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import { getCarrierReportAsync } from "@/store/features/carrier-report/carrierReportAsyncThunks";
import { getDocketNumber } from "@/utils/getDocketNumber";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import { accessTokenSelector } from "@/store/features/account/accountSelectors";
import { refreshTokenSelector } from "@/store/features/account/accountSelectors";
import {
  carrierReportDataSelector,
  carrierReportLoadingSelector,
} from "@/store/features/carrier-report/carrierReportSelectors";
import { InDevelopmentView } from "@/components/in-development-view";
import { Loader } from "@/components/loader";

import { AuthorityStatuses } from "./AuthorityStatuses";
import { Inspections } from "./Inspections";
import { DBAData } from "./DBAData";
import { Heading } from "./Heading";

export const CarrierReportView = () => {
  const dispatch = useAppDispatch();
  const loadData = useAppSelector(loadDataSelector);
  const accessToken = useAppSelector(accessTokenSelector);
  const refreshToken = useAppSelector(refreshTokenSelector);
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const loading = useAppSelector(carrierReportLoadingSelector);

  const { entity_type, service_areas } = carrierReportData || {};

  useEffect(() => {
    if (!carrierReportData)
      dispatch(
        getCarrierReportAsync({
          mc: getDocketNumber(loadData?.docket),
          tokens: {
            accessToken,
            refreshToken,
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader show={loading} />;
  }

  return (
    <div className="flex flex-col gap-3 h-full">
      {entity_type?.includes("CARRIER") ? (
        <>
          <Heading />
          <div className="flex gap-3">
            <DBAData />
            {!!service_areas && <ChoroplethMap data={service_areas} />}
          </div>
          <AuthorityStatuses />
          <Inspections />
        </>
      ) : (
        <InDevelopmentView />
      )}
    </div>
  );
};
