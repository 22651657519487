import { TRootState } from "@/store/app/store";
import {
  resetAccountState,
  setAccountData,
  setTokens,
} from "@/store/features/account/accountSlice";
import { resetCarrierReportState } from "@/store/features/carrier-report/carrierReportSlice";
import {
  resetEmailState,
  setActiveTemplate,
  setEmailData,
  setRecieverEmail,
} from "@/store/features/email/emailSlice";
import {
  resetLoadInfoState,
  setLoadData,
} from "@/store/features/load-info/loadInfoSlice";
import { resetTabsState, setActiveTab } from "@/store/features/tabs/tabsSlice";
import {
  resetTripInfoState,
  setActiveUnitSystem,
  setIsUnitsSwitchAccessible,
} from "@/store/features/trip-info/tripInfoSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { IMessage, ITokens } from "@/types/common";
import {
  getAccountsAsync,
  getTemplatesAsync,
} from "@/store/features/email/emailAsyncThunks";
import { getUserInfoAsync } from "@/store/features/account/accountAsyncThunks";

type ResetStoreFunc = (
  dispatch: ThunkDispatch<TRootState, any, any>,
  config: {
    isNewUser: boolean;
    isLoadLink: boolean;
    tokens: ITokens;
    data: IMessage;
  }
) => void;

export const resetStore: ResetStoreFunc = async (
  dispatch,
  { isNewUser, isLoadLink, tokens, data }
) => {
  if (isNewUser) {
    dispatch(resetAccountState());
    dispatch(resetLoadInfoState());
    dispatch(resetTabsState());
    dispatch(resetCarrierReportState());
  }
  dispatch(resetTripInfoState());
  dispatch(resetEmailState());

  dispatch(setTokens(tokens));
  await Promise.all([
    dispatch(getAccountsAsync(tokens)),
    dispatch(getUserInfoAsync(tokens)),
    dispatch(getTemplatesAsync(tokens)),
  ]);
  dispatch(setLoadData(data.data));
  dispatch(setAccountData(data.activeAccount));
  dispatch(setEmailData(data.data.email_data));
  dispatch(setActiveTemplate(data.activeTemplateId));

  dispatch(setIsUnitsSwitchAccessible(isLoadLink));
  dispatch(setActiveUnitSystem(isLoadLink ? "metric" : "imperial"));

  if (data.data.email_data.email) {
    dispatch(setRecieverEmail(data.data.email_data.email));
    dispatch(setActiveTab("email"));
  } else {
    dispatch(setActiveTab("loadInfo"));
  }
};
