import { FC, useMemo } from "react";
import * as d3 from "d3";
import { ServiceAreas } from "@/types/carrierReport";
import { Group } from "@/components/group";
import { MapLegendItem } from "./MapLegendItem";
import geoData from "@/assets/data/usa-geo.json";

const MAP_WIDTH = 268;
const MAP_HEIGHT = 185;

interface IProps {
  data: ServiceAreas;
}

export const ChoroplethMap: FC<IProps> = ({ data }) => {
  const projection = d3
    .geoAlbersUsa()
    .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2])
    .scale(370);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const serviceAreasCount = Object.keys(data).length;

  const allSvgPaths = useMemo(
    () =>
      geoData.features.map((shape: any) => {
        const regionKey = Object.keys(data).find(
          (region) => region === shape.properties.id
        );
        const value = regionKey ? data?.[regionKey] : 0;

        const color = value
          ? value > 9
            ? "#2C476A"
            : value > 5
            ? "#406595"
            : value > 3
            ? "#5B8CCC"
            : "#999999"
          : "#999999";

        return (
          <path
            key={shape.properties.GEO_ID}
            d={geoPathGenerator(shape) as string}
            stroke="white"
            strokeWidth={0.5}
            fill={color}
            fillOpacity={1}
          />
        );
      }),
    [data, geoPathGenerator]
  );

  return (
    <Group>
      <div className="flex flex-col gap-2 items-center">
        <div className=" w-full flex justify-between items-center">
          <p className="text-sm text-gray-500">Inspection areas</p>
          <p className="text-sm text-gray-950 font-medium">
            {serviceAreasCount}
          </p>
        </div>
        <svg width={MAP_WIDTH} height={MAP_HEIGHT}>
          {allSvgPaths}
        </svg>
        <div className="flex justify-center items-center text-gray-600 text-sm gap-5">
          <MapLegendItem value="10+" color="#2C476A" />
          <MapLegendItem value="6-9" color="#406595" />
          <MapLegendItem value="3-6" color="#5B8CCC" />
          <MapLegendItem value="0-3" color="#999999" />
        </div>
      </div>
    </Group>
  );
};
